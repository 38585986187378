const isRequired = (value: string): boolean => {
  if (value.length > 0) {
    return true;
  }
  return false;
};

const isValidLength = (
  value: string,
  minimum: number,
  maximum: number,
): boolean => {
  if (value.length >= minimum && value.length <= maximum) {
    return true;
  }
  return false;
};

const isValidCase = (
  value: string,
  chracterSize: number,
  caps: boolean,
): boolean => {
  let rule: RegExp;
  if (!caps) {
    rule = new RegExp(`^(?=(.*[a-z]){${chracterSize}}).+`);
  } else {
    rule = new RegExp(`^(?=(.*[A-Z]){${chracterSize}}).+`);
  }
  return rule.test(value);
};

const isNumberPresent = (value: string, chracterSize: number): boolean => {
  const rule = new RegExp(`^(?=(.*[0-9]){${chracterSize}}).+`);
  return rule.test(value);
};
const isContainWhitespace = (value: string) => value.indexOf(' ') >= 0;

const isValidSpecialCharacter = (value: string, chracterSize: number): boolean => {
  const rule = new RegExp(`^(?=(.*[=+-^$*.[]{}()?"!@#%&/\\,><':;|_~\`]){${chracterSize}}).+`);
  return rule.test(value);
};

const checkRequireField = (
  data: string | number,
  fieldName: string,
): string => {
  let message = '';
  const secondTypeFields = ['First Name', 'Last Name'];
  const passwordTypeFields = ['password', 'Old Password', 'New Password', 'Confirm Password'];
  if (data === undefined || (typeof data === 'string' && data.trim() === '')) {
    if (fieldName === 'email') {
      message = `The ${fieldName} field cannot be blank. Please enter your registered ${fieldName}.`;
    } else if (passwordTypeFields.includes(fieldName)) {
      message = 'The password field cannot be blank. Please enter a valid password.';
    } else if (secondTypeFields.includes(fieldName)) {
      message = `The ${fieldName} field cannot be blank. Please enter a valid ${fieldName}.`;
    } else {
      message = `Please enter a value for ${fieldName}.`;
    }
  }
  return message;
};
const isValidPhoneNumber = (number: string): boolean => {
  const rule = new RegExp('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$');
  return rule.test(number);
};

const isValidEmail = (email: string): boolean => {
  const sQtext = '[^\\x0d\\x22\\x5c\\x80-\\xff]';
  const sDtext = '[^\\x0d\\x5b-\\x5d\\x80-\\xff]';
  const sAtom = '[^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+';
  const sQuotedPair = '\\x5c[\\x00-\\x7f]';
  const sDomainLiteral = `\\x5b(${sDtext}|${sQuotedPair})*\\x5d`;
  const sQuotedString = `\\x22(${sQtext}|${sQuotedPair})*\\x22`;
  const sDomainRef = sAtom;
  const sSubDomain = `(${sDomainRef}|${sDomainLiteral})`;
  const sWord = `(${sAtom}|${sQuotedString})`;
  const sDomain = `${sSubDomain}(\\x2e${sSubDomain})*`;
  const sLocalPart = `${sWord}(\\x2e${sWord})*`;
  const sAddrSpec = `${sLocalPart}\\x40${sDomain}`;
  const sValidEmail = `^${sAddrSpec}\\.[a-zA-Z]{2,}$`;
  return new RegExp(sValidEmail).test(email);
};
const validatePassword = (
  password: string,
  email: string,
  first?: string,
  last?: string,
): string[] => {
  const error: string[] = [];
  if (!isRequired(password)) {
    error.push('It should not be empty');
  }
  if (!isValidLength(password, 8, 99)) {
    error.push('Length should be greater then 8 and smaller then 99');
  }
  if (isContainWhitespace(password)) {
    error.push('Should not contain whitespace');
  }
  if (!isValidCase(password, 1, true)) {
    error.push('must have atleast 1 uppercase character');
  }
  if (!isNumberPresent(password, 1)) {
    error.push('must have atleast 1 Number Present');
  }
  if (!isValidSpecialCharacter(password, 0)) {
    error.push('must have atleast 1 special character');
  }
  if (password.toLowerCase().indexOf(email.toLocaleLowerCase()) !== -1) {
    error.push('Should not contain email');
  }
  if (!!first && password.toLowerCase().indexOf(first.toLowerCase()) !== -1) {
    error.push('Should not contain first name');
  }
  if (!!last && password.toLowerCase().indexOf(last.toLowerCase()) !== -1) {
    error.push('Should not contain last name');
  }
  return error;
};

const containsValidString = (field: string) => {
  let isValid = true;
  if (field && !/^[a-zA-Z]+$/.test(field)) {
    isValid = false;
  }
  return isValid;
};

const isNameValid = (field: string, fieldName: string, minimumLength: number) => {
  if (!containsValidString(field)
    || (field && field.length < minimumLength)
  ) {
    return `Please enter a valid ${fieldName}.`;
  }
  return '';
};

export {
  validatePassword,
  checkRequireField,
  isContainWhitespace,
  isNumberPresent,
  isRequired,
  isValidCase,
  isValidEmail,
  isValidLength,
  isValidPhoneNumber,
  isValidSpecialCharacter,
  isNameValid,
};
